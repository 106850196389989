* {
  margin: 0;
  padding: 0;
}

/* ******************************************************************************** */
/* button section */
.Login_btn {
  background: transparent;
  font-family: inherit;
  display: inline-block;
  width: 7em;
  height: 2.6em;
  line-height: 2.5em;
  overflow: hidden;
  cursor: pointer;
  margin: 20px;
  font-size: 17px;
  z-index: 1;
  color: white;
  background-color: #ef4f5f;
  border: 1.5px solid whitesmoke;
  border-radius: 6px;
  position: relative;
  font-weight: 500;
}

.Login_btn::before {
  /* position: absolute; */
  content: "";
  border-color: rgba(255, 255, 255, 1);
  color: #fff;
  width: 150px;
  height: 200px;
  z-index: -1;
  background: #ff416c;
  border-radius: 50%;
}

.Login_btn:hover {
  border-color: #ef4f5f;
  background: #fff;
  color: #ef4f5f;
}

.btn {
  font-weight: 600;
  padding: 8px 10px;
  border-radius: 30px;
  color: #fff;
  background: #2f2f2f;
  border-color: #2f2f2f;
}
.btn:hover {
  color: #fff;
  background: #222;
  border-color: #222;
}
.btn:active,
.btn:focus {
  outline: none !important;
  box-shadow: none;
}

.btn.btn-white-outline {
  background: transparent;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.3);
}
.abc-btn {
  background: transparent;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.3);
  padding: 9px 41px;
}
.btn.btn-white-outline:hover {
  border-color: rgba(255, 255, 255, 1);
  color: #fff;
}
.btn_main {
  background: transparent;
  font-family: inherit;
  display: inline-block;
  width: 8em;
  height: 3em;
  overflow: hidden;
  cursor: pointer;
  background-color: #ef4f5f;
  font-size: 17px;
  z-index: 1;
  color: white;
  border: 1.5px solid #ef4f5f;
  border-radius: 30px;
  position: relative;
  /* justify-content: center; */
  font-weight: 500;
  /* background: linear-gradient(84.22deg, #FF416C 5.89%, #FF4B2B 93.59%); */
}

.btn_main::before {
  /* position: absolute; */
  content: "";
  border-color: #ef4f5f;
  color: #000000;
  background: white;
  width: 150px;
  height: 200px;
  z-index: -1;
  border-radius: 50%;
}

.btn_main:hover {
  border-color: #ef4f5f;
  color: #000000;
  background: white;
}
.product_contain {
  padding: 25px;
}
.cm-btn {
  background-color: #ef4f5f;
  border: none;
  font-weight: 600;
  padding: 2px 8px;
}
/* Button end */
/* ******************************************************************************** */
/* header section start */

.Header_homepage h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  color: #1a1a1a;
  position: relative;
  justify-content: center;
}

.Header_homepage h1 span {
  margin-top: 40px;
  text-transform: none;
  font-size: 32px;
  font-weight: bold;
  color: #000000;
  letter-spacing: -0.005em;
}

.Header_homepage h1:after {
  content: "";
  display: block;
  width: 60px;
  height: 4px;
  background-color: #ef4f5f;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  bottom: -10px;
}

/* header section end */
/* ******************************************************************************** */
/* Navbar start */
.navbar {
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.navbar .navbar-brand {
  font-size: 30px;
}

.navbar .nav-item {
  padding: 5px 10px;
}

.navbar .nav-link {
  font-size: 16px;
  margin-left: 5px;
  color: #333333 !important; /* Set font color to black */
  font-weight: 600;
  transition: 0.3s all ease;
  position: relative;
}
.navbar .nav-link:hover {
  opacity: 1;
  color: #ef4f5f !important; /* Changes the font color on hover */
}
/* Custom hamburger icon */
.navbar-toggler {
  position: relative;
  width: 50px;
  height: 50px;
  border: none;
}

.navbar-toggler span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #ef4f5f;
  margin: 4px 0;
  transition: all 0.3s ease-in-out;
}

/* When the navbar is collapsed (hamburger menu) */
.navbar-toggler.collapsed span:nth-child(1) {
  transform: rotate(0);
}

.navbar-toggler.collapsed span:nth-child(2) {
  opacity: 1;
}

.navbar-toggler.collapsed span:nth-child(3) {
  transform: rotate(0);
}

/* When the navbar is expanded (cross icon) */
.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: rotate(45deg) translate(4px, 4px);
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.navbar img {
  height: 50px;
  /* margin-left: 1rem; */
}
/* Navbar end */
/* ******************************************************************************** */
.skewed-box {
  height: 600px;
  position: relative;
  overflow: hidden;
  background-color: #ef4f5f;
}

.skewed-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 87%;
  height: 600px;
  background-color: #ef4f5f;
  transform: skewX(342deg);
  transform-origin: top left;
  z-index: 99;
}

.skewed-box::after {
  position: absolute;
  right: -2px;
  background-image: url("/public/assests/image/Home/rectangle_1.png");
  content: "";
  width: 370px;
  height: 100%;
  z-index: 100;
  top: 0;
  background-size: cover;
}

.content {
  position: relative;
  z-index: 150;
  color: white;
  padding: 50px;
  width: 80%;
  top: 130px;
}

section.main_home {
  background: #f74b60;
}

.image-side {
  /* background-image: url(/build/assests/image/Home/Home_banner_1.png); */
  background-image: url("../public/assests/image/Home/Home_banner_1.png");

  background-size: cover;
  background-position: center;
  height: 600px;
  right: 0;
  position: absolute;
  width: 55%;
}

.main_text {
  position: absolute;
  top: 50%;
  left: 28%;
  transform: translateY(-50%);
  z-index: 2;
  color: white;
}

.main_text h1 {
  font-size: 26px;
}

.main_text p {
  margin-bottom: 20px;
}

.btn_main_home {
  background: transparent;
  display: inline-block;
  width: 8em;
  overflow: hidden;
  cursor: pointer;
  margin: 10px 25px 0px 0px;
  background-color: #ef4f5f;
  font-size: 17px;
  z-index: 1;
  color: white;
  border: 1.5px solid white;
  border-radius: 6px;
  position: relative;
  font-weight: 500;
}

.btn_main_home:hover {
  border-color: #ef4f5f;
  color: #000000;
  background: white;
}

/* Changes from 768px */

/* ******************************************************************************** */
/* home 2 start */
.banner {
  position: relative;
  overflow: hidden; /* Ensures no overflow */
}

.banner img {
  width: 100%; /* Makes the image stretch to full width */
  height: auto; /* Maintains aspect ratio */
  max-height: 80vh; /* Optional: sets a max height */
  object-fit: cover; /* Covers the area without distortion */
}

/* Optional: Media queries for further customization */
@media (max-width: 768px) {
  .banner img {
    max-height: 60vh; /* Reduce max height on smaller screens */
  }
}
.box-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Style for the right main image */
.right-main-img {
  max-width: 100%;
  /* height: 500px; */
  position: relative;
  z-index: 1;
}
.left-content {
  justify-content: center;
  margin-top: 25px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  position: relative;
  z-index: 9999999999999999;
  background: white;
}
/* Style for top-right rectangle */
.rectangle-top {
  position: absolute;
  top: -20px;
  right: 33px;
  /* width: 20%; */
}

/* Style for bottom-right rectangle */
.rectangle-bottom {
  position: absolute;
  bottom: -25px;
  right: 32px;
  width: 20%;
}
/* home 2 end */
/* ******************************************************************************** */
/* advertise design start */
.adv_text {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 2rem;
}
.red-star {
  color: red;
  margin-right: 8px;
}
.text-container {
  display: flex;
  align-items: center;
  font-size: 30px;
  color: #ef4f5f;
}
.shadow-effect {
  font-family: "Londrina Shadow", sans-serif;
  font-weight: 400;
  font-style: normal; /* Using Jacques Francois Shadow font */
}
.simple_text {
  font-weight: 600;
}
/* advertise design end */
/* ******************************************************************************** */

/* feature start */
.product-image-section {
  text-align: center;
  position: relative;
}
.text {
  font-size: 16px;
}
.left-arrow {
  position: absolute;
  top: 0px;
  left: -65px;
  width: 100px;
  height: auto;
  z-index: 10;
}

.isolation-image {
  display: block;
  max-width: 100%;
  height: 100px;
  position: absolute;
  right: -75px;
  bottom: -17px;
  z-index: 1;
}

.product-image {
  max-width: 100%;
  height: 600px;
  display: block;
  position: relative;
  z-index: 70000;
}

/* Vertical dot in top-right corner of product image */
/* Vertical dot in top-right corner of product image */
.vertical-dot {
  top: -18px;
  right: -37px;
  width: 122px;
  height: 150px;
  z-index: 153;
  position: absolute;
  z-index: -20px;
}

/* Horizontal dot in bottom-left corner of product image */
.horizontal-dot {
  position: absolute;
  bottom: 0px;
  left: -60px;
  width: 185px;
  height: 108px;
  z-index: -19;
}
.ip-features-container {
  padding: 20px;
  margin: 0 auto;
}

.ip-features-left,
.ip-features-right {
  /* padding: 0 40px; */
}

.ip-features-item,
.ip-features-item-right {
  background-color: #fff;
  padding: 20px 0px 20px;
  border-radius: 8px;
}

.ip-features-item h3,
.ip-features-item-right h3 {
  margin-bottom: 10px;
  font-size: 18px;
  margin-left: 15px;
  font-weight: 600;
}

.ip-features-item p,
.ip-features-item-right p {
  color: #3f4756;
  line-height: 1.5;
  font-size: 16px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .ip-features-left,
  .ip-features-right {
    padding: 20px;
  }
  .isolation-image {
    display: none;
  }
  .product-image {
    height: auto;
  }
}

/* feature end */
/* ******************************************************************************** */
/* how it works start */

.course_section {
  margin: 30px;
  font-family: "Product Sans", sans-serif;
}

.card {
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 360px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding-left: 20px; /* Apply consistent padding to the entire card */
  background: white;
  position: relative;
  z-index: 9999999999999999;
}
.number-box {
  background-color: #cd3443;
  color: white;
  font-size: 20px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px; /* Space between number box and title */
  padding-left: 0; /* Remove padding so it aligns properly */
}

.card-title {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 600;
  color: #000000;
  font-size: 16px;
  padding-left: 0; /* Ensure same left padding as number box */
}

.card-body {
  position: relative;
  bottom: 0;
  max-height: 50%;
  color: #3f4756;
  font-size: 14px;
  padding-left: 0; /* Ensure same left padding as number box */
}

.course_section .card-column .card .card-text {
  color: #cdcdcd;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
}

@media screen and (max-width: 480px) {
  .course_section .card-column .card {
    width: 100%;
  }
  .course_section .card-column .card .card-body button {
    width: 100%;
  }
  .course_section .card-column .card .card-body {
    /* margin-top: 55%; */
    /* height: 200px; */
  }
}

/* Arrow styles */
.right-arrow-between-cards-1-2,
.right-arrow-between-cards-3-4,
.left-arrow-below-cards-2-3 {
  position: absolute;
  width: 150px;
  height: 70px; /* Adjust size of arrows */
}

/* Right arrow between card 1 and card 2 */
.right-arrow-between-cards-1-2 {
  top: -14px;
  left: 18%;
}

/* Left arrow below card 2 and card 3 */
.left-arrow-below-cards-2-3 {
  bottom: -70px;
  left: 50%;
  transform: translateX(-50%);
}

/* Right arrow between card 3 and card 4 */
.right-arrow-between-cards-3-4 {
  top: -10px;
  left: 68%;
}

/* Media Queries */

/* For smaller screens (max-width: 768px) */
@media (max-width: 768px) {
  .right-arrow-between-cards-1-2,
  .left-arrow-below-cards-2-3,
  .right-arrow-between-cards-3-4 {
    display: none; /* Hide arrows on smaller screens */
  }
}

/* how it works end*/

/* ******************************************************************************** */
/* qr sticker start */
.QR_sticker {
  background-color: #ef435c;
  padding-top: 30px;
  padding-bottom: 30px;
}
.left-content_QR_sticker {
  padding-left: 50px; /* Adjust this value as needed */
}
.left-content_QR_sticker h2,
.left-content_QR_sticker h6,
.left-content_QR_sticker p {
  color: #ffffff; /* Set text color to white */
}

.left-content_QR_sticker h2 {
  margin-bottom: 10px;
  font-size: 24px;
}

.left-content_QR_sticker h6 {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Drop shadow for h6 text */
}

.left-content_QR_sticker p {
  margin-bottom: 20px;
}

/* qr sticker end */
/* ******************************************************************************** */
/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials {
  background-color: #292929 !important;
}
.testimonial {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  color: #3d5a80;
}
.testimonial-slide {
  padding: 40px 20px;
}
.testimonial_box-top {
  background-color: #f3f3f3;
  padding: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* box-shadow: 2px 2px 20px rgba(152, 193, 217, 0.493); */
}
.testimonial_box-icon {
  padding: 10px 0;
}
.testimonial_box-icon i {
  font-size: 25px;
  color: #14213d;
}
.testimonial_box-text {
  padding: 10px 0;
}
.testimonial_box-text p {
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
}
.testimonial_box-img {
  padding: 20px 0 10px;
  display: flex;
  justify-content: center;
}
.testimonial_box-img img {
  width: 90px;
  height: 90px;
  border-radius: 50px;
  border: 2px solid #e5e5e5;
}
.testimonial_box-name {
  padding-top: 10px;
}
.testimonial_box-name h4 {
  font-size: 20px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 0;
}
.testimonial_box-job p {
  color: #666666;
  font-size: 14px;
  /* text-transform: uppercase; */
  letter-spacing: 1px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

/* contact start */

.contact .info {
  /* border-top: 3px solid #ff4b2b; */
  /* border-bottom: 3px solid #ff4b2b; */
  background: #fff;
  width: 100%;
}

.contact .info i {
  font-size: 20px;
  float: left;
  width: 32px;
  height: 44px;
  color: #ef4f5f;
  display: flex;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #222222;
}

.contact .info p {
  font-size: 14px;
  color: #555555;
}

.phone,
.email {
  display: flex;
  align-items: baseline;
}

.contact .info .social-links {
  padding-left: 60px;
}

.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

/* .contact .info .social-links a:hover {
  background: linear-gradient(84.22deg, #ff416c 5.89%, #ff4b2b 93.59%);
  color: #fff;
} */

/* .contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: linear-gradient(84.22deg, #ff416c 5.89%, #ff4b2b 93.59%);
  color: #fff;
} */

.contact .php-email-form {
  width: 100%;
  /* border-top: 3px solid #ff4b2b; */
  /* border-bottom: 3px solid #ff4b2b; */
  padding: 30px;
  background: #fff;
  filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
}
.required-asterisk {
  color: red;
}

.contact .form-group {
  padding-bottom: 8px;
}

.contact .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #d3d3d3;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/* contact end */
/* ******************************************************************************** */
/* footer start */
/* footer */
.footer-section {
  padding: 80px 0;
  background: #292929;
  justify-content: space-between;
}

.footer-section a {
  text-decoration: none;
  /* color: #2f2f2f; */
  transition: 0.3s all ease;
  color: white;
}
.footer-section a:hover {
  color: whitesmoke;
}
.footer-section .links-wrap ul li {
  margin-bottom: 10px;
}
.footer-section .footer-logo-wrap .footer-logo {
  font-size: 32px;
  font-weight: 500;
  text-decoration: none;
  color: #3b5d50;
}
.footer-section .custom-social li {
  margin: 2px;
  display: inline-block;
}
.footer-section .custom-social li a {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  display: inline-block;
  /* background: #dce5e4; */
  color: white;
  background: #ef4f5f;
  border-radius: 50%;
}
.footer-section .custom-social li a:hover {
  background: linear-gradient(84.22deg, #ff416c 5.89%, #ff4b2b 93.59%);
  color: #fff;
}
.footer-section .border-top {
  border-color: #dce5e4;
}
.links-wrap .list-unstyled {
  justify-content: space-between;
  font-family: sans-serif;
}
.links-wrap .list-unstyled li a {
  color: #c6c6c6;
}
.links-wrap h4 {
  border-bottom: 1px dotted white;
}
.footer-bottom1 {
  background-color: rgb(29, 29, 29);
  height: 40px;
  justify-content: center;
  text-align: center;
  padding: 10px 0px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

/* footer end */
/* ******************************************************************************** */
/* ///////////////////// Innerpage  start //////////////////////////////////////*/

/* productdetailpage start */

/* .container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    } */

.main {
  width: 100%;
}
.breadcrumb__links a {
  text-decoration: none;
  color: #666666;
}
.breadcrumb-separator {
  color: #ef4f5f;
  margin: 0 10px;
}
/* .slider {
    margin-bottom: 20px;
    }
    
    .slider-for {
    margin-bottom: 10px;
    }
    
    .slider-for-img img {
    height: 500px;
    width: 100%;
    border-radius: 6px;
    }
    .slider-nav {
      display: flex;
      justify-content: space-between;
      margin: 0 -10px; 
    }
    .slider-nav-img {
      width: 100%; 
      padding: 0 10px;
    }
    
    .slider-nav-img img {
    width: 100%;
    border-radius: 6px;
    height: 150px;
    margin-top: 5px;
    object-fit: cover;
    
    }
    
    .slick-slide {
    margin: 0 5px;
    }
    
    .slick-prev,
    .slick-next {
    font-size: 0;
    color: transparent;
    border: none;
    }
    
    .slick-prev:before,
    .slick-next:before {
    font-size: 45px;
    color: red;
    }
    
    .slick-dots li button:before {
    font-size: 12px;
    color: #EF4F5F;
    }
    
    .slick-dots li.slick-active button:before {
    color: #EF4F5F;
    } */
.product-link {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.9rem;
  display: inline-block;
  margin-bottom: 0.5rem;
  color: rgb(255, 255, 255);
  text-decoration: none;
  background: rgb(239, 79, 95);
  padding: 0px 0.4rem;
  transition: all 0.5s ease 0s;
  border-radius: 3px;
}

.product__details__text h3 {
  color: #111111;
  font-weight: 600;
  margin-bottom: 12px;
}

.product__details__text h3 span {
  display: block;
  font-size: 14px;
  color: #444444;
  text-transform: none;
  font-weight: 400;
  margin-top: 5px;
}

.product__details__text .rating {
  margin-bottom: 16px;
}

.product__details__text .rating i {
  font-size: 12px;
  color: #fec72d;
  margin: 2px 4px;
}

.product__details__text .rating span {
  font-size: 16px;
  color: #666666;
  margin-left: 5px;
}

.product__details__text p {
  color: #666666;
  margin-bottom: 28px;
}

.product__details__price {
  font-size: 30px;
  font-weight: 600;
  color: #ef4f5f;
  margin-bottom: 30px;
  margin-left: -9px;
}

.product__details__price span {
  font-size: 18px;
  color: #b1b0b0;
  text-decoration: line-through;
  margin-left: 10px;
  display: inline-block;
}

.product__details__button {
  overflow: hidden;
  margin-bottom: 25px;
}
/*     
    .cart-btn {
    display: inline-block;
    font-size: 14px;
    color: #ffffff;
    background: #EF4F5F;
    font-weight: 400;
    width: 150px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 6px;
    text-decoration: none;
    border-color: white;
    }
     */
.product__details__button ul {
  float: left;
}

.product__details__button ul li {
  list-style: none;
  display: inline-block;
  margin-right: 5px;
}

.product__details__button ul li:last-child {
  margin-right: 0;
}

.product__details__button ul li a {
  display: inline-block;
  height: 50px;
  width: 50px;
  border: 1px solid #ebebeb;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  padding-top: 1px;
}

.product__details__button ul li a span {
  font-size: 18px;
  color: #666666;
}

.quantity {
  display: flex;
  background-color: #ef4f5f36;
  align-items: center;
}

.quantity-field {
  width: 220px;
  height: 40px;
}

.quantity-field .value-button {
  border: 1px solid #ddd;
  margin: 0px;
  width: 60px;
  /* height: 100%; */
  padding: 5px 10px;
  background: #ef4f5f;
  color: white;
  outline: none;
  cursor: pointer;
}

.quantity-field .value-button:hover {
  background: #ac3844;
}

.quantity-field .value-button:active {
  background: rgb(210, 210, 210);
}

.quantity-field .decrease-button {
  margin-right: -4px;
  border-radius: 8px 0 0 8px;
}

.quantity-field .increase-button {
  margin-left: -4px;
  border-radius: 0 8px 8px 0;
}

.quantity-field .number {
  display: inline-block;
  text-align: center;
  border: none;
  /* border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; */
  margin: 0px;
  width: 50px;
  /* height: 100%; */
  line-height: 40px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: #ef4f5f01;
}

.quantity-field .number::selection {
  background: none;
}

.cart-btn {
  /* margin-left: 20px; */
  /* text-decoration: none;
    color: #000;
    display: flex;
    align-items: center;
    border-color: white; */
}

.icon_bag_alt {
  margin-right: 5px;
}

.cart-btn {
  display: inline-block;
  font-size: 14px;
  width: 150px;
  color: #ffffff;
  background: #ef4f5f;
  font-weight: 600;
  /* text-transform: uppercase; */
  margin: 2px 20px 20px 2px;
  /* margin-right: 40px; */
  font-size: 17px;
  border-radius: 10px;
  float: left;
  /* border-color: white; */
  margin-right: 10px;
  margin-bottom: 10px;
  border-color: #ef4f5f;
}
.product__details__price .strike_price {
  text-decoration: line-through;
  margin-right: 5px;
  color: #999999;
  font-size: 18px;
}

.cart-btn:hover {
  background-color: white; /* Change background on hover */
  color: #ef4f5f;
  border-color: #ef4f5f; /* Change text color for better contrast */
}

/* productdetailpage end */

/* login start */
.logo_heading {
  color: #d0d0d0;
  text-align: center;
}
.company-pic {
  max-width: 100%;
  height: auto;
}
.verification-form {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 15px;
}
.login_heading {
  text-align: center;
  font-weight: bold;
}
.btn-block {
  background-color: #ef4f5f;
  color: white;
  font-weight: 500;
  margin-top: 20px;
  border-radius: 10px;
  border-color: white;
  padding: 16px 25px;
}
.btn-block:hover {
  color: #b1b0b0;
  background-color: #e23744;
  border-color: white;
}

/* login end */

.slider-for {
  position: relative;
}
.custom-arrow {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  position: absolute;
}

.custom-arrow-prev {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #e23744;
  background-color: whitesmoke;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.custom-arrow-next {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #e23744;
  background-color: whitesmoke;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.custom-arrow i {
  font-size: 20px;
}

/* Loader.css */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Ensure it stays fixed in the viewport */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); /* Center the loader exactly */
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Add a semi-transparent background if needed */
  z-index: 9999; /* Ensure it appears above other elements */
  width: 100%; /* Optional: Can cover the entire screen */
  height: 100%; /* Optional: Can cover the entire screen */
}

.dot {
  width: 15px;
  height: 15px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #ef4f5f; /* Change color as needed */
  animation: bounce 1.4s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.button-text-hidden {
  visibility: hidden;
}

button:disabled .button-text-hidden {
  visibility: visible;
}

/* Innerpage design */
.section-gap {
  background: #fff;
  font-family: "Nunito Sans", sans-serif !important;
}

.term-head {
  position: relative;
  font-size: 2em; /* Adjust as needed */
  font-weight: bold; /* Optional: make the text bold */
  background: linear-gradient(84.22deg, #ff416c 5.89%, #ff4b2b 93.59%);
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
}

/* .term-head::before,
.term-head::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 200px; 
  height: 2px; 
  background: #ff4b2b;
  transform: translateY(-50%);
}

.term-head::before {
  left: 0;
}

.term-head::after {
  right: 0;
} */

.quick_main {
  display: "flex";
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.quick_logo i {
  cursor: pointer;
  font-size: 30px;
  margin: "0 20px";
}
.quick-contact-container {
  text-align: center;
  margin: 20px auto;
  padding: 20px;
  max-width: 600px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.quick-contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-container {
  cursor: pointer;
  padding: 10px;
  background: linear-gradient(84.22deg, #ff416c 5.89%, #ff4b2b 93.59%);
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 20px;
}

.phone-icon,
.sms-icon {
  font-size: 30px;
  color: white;
}

.or-text {
  font-size: 18px;
  color: #777;
  margin: 10px 0;
}
.message-text {
  font-weight: 500;
  color: #666666;
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.message-text.selected {
  color: #ef4f5f !important; /* Change color to red when selected */
}
/* Add this CSS to your stylesheet */
.custom-radio:checked {
  accent-color: #ef4f5f; /* Set the color for the selected radio */
}

.custom-radio {
  border: none; /* Remove the border if needed */
}

.contact-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.message-input {
  font-size: 16px;
  padding: 8px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}

.message-input:focus {
  border-color: #ff4b2b;
}

.send-button {
  font-size: 16px;
  padding: 8px 16px;
  background-color: #ff4b2b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.send-button:hover {
  background-color: #ff416c;
}

.message-input::-webkit-calendar-picker-indicator {
  display: none;
}
.quantity-section {
  align-items: center;
  margin-bottom: 20px;
}

.quantity-section h5 {
  margin-right: 20px;
  font-size: 15px;
  color: #333333;
  font-weight: normal;
}
.quantity {
  display: flex;
  align-items: center;
  border: 1.5px solid #ef4f5f;
  border-radius: 5px;
  overflow: hidden;
  width: 150px;
}

.value-button {
  width: 20px;
  font-size: 18px;
  background-color: #f8f8f8;
  border: none;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
}
.value-button:hover {
  background-color: #ddd;
}
.decrease-button {
  border-right: 1px solid white;
  width: 40px;
  color: #ef4f5f;
  font-weight: 600;
  font-size: 24px;
}

.increase-button {
  border-left: 1px solid white;
  width: 40px;
  /* background: #EF4F5F; */
  color: #ef4f5f;
  font-weight: 600;
  font-size: 24px;
}

.number {
  width: 60px;
  text-align: center;
  font-size: 18px;
  /* background-color: #fff; */
}
.minus {
  background-color: #ef4f5f00;
  border-color: #ef4f5f00;
  margin-bottom: 3px;
}
.minus:hover {
  background-color: #ef4f5f00;
}
/* white-Navbar start */
.new-navbar {
  background: #fff !important;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.new-navbar .navbar-brand {
  font-size: 32px;
  font-weight: 600;
}
.new-navbar .navbar-brand > span {
  opacity: 0.4;
}
.new-navbar .navbar-toggler {
  border-color: transparent;
  text-align: center;
}
.new-navbar .navbar-toggler:active,
.new-navbar .navbar-toggler:focus {
  box-shadow: none;
  outline: none;
}

.new-navbar .new-navbar-nav li a {
  font-weight: 500;
  color: black !important;
  /* opacity: 0.5; */
  transition: 0.3s all ease;
  position: relative;
}
@media (min-width: 768px) {
  .new-navbar .new-navbar-nav li a:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 8px;
    background: white;
    color: red;
    height: 1px;
    opacity: 1;
    visibility: visible;
    width: 0;
    transition: 0.15s all ease-out;
  }
}
.new-navbar .new-navbar-nav li a:hover {
  color: #ef4f5f !important; /* Change text color on hover */
}
.new-navbar .new-navbar-nav li a:hover:before {
  width: calc(100% - 16px);
}
.new-navbar .new-navbar-nav li.active a {
  color: black !important; /* Ensure active link remains black */
}
.new-navbar .new-navbar-nav li.active a:before {
  width: calc(100% - 16px);
}

/* .new-navbar .custom-navbar-cta {
  text-align: center;
} */

.new-navbar .custom-navbar-cta li {
  margin-left: 0px;
  margin-right: 0px;
}
.new-navbar .custom-navbar-cta li:first-child {
  margin-right: 20px;
}
.nav-item-spacing {
  margin-right: 20px;
}

.nav-item-spacing:last-child {
  margin-right: 0;
}

.Login_new {
  background: transparent;
  font-family: inherit;
  display: inline-block;
  width: 6em;
  justify-content: center; /* Horizontally center the text */
  align-items: center; /* Vertically center the text */
  height: 2.6em;
  line-height: 1em;
  overflow: hidden;
  cursor: pointer;
  margin: 20px;
  font-size: 17px;
  z-index: 1;
  color: white;
  background-color: #ef4f5f;
  border: 1.5px solid whitesmoke;
  justify-content: center;
  border-radius: 6px;
  position: relative;
  font-weight: 500;
}

.Login_new::before {
  /* position: absolute; */
  content: "";
  border-color: rgba(255, 255, 255, 1);
  color: #fff;
  width: 150px;
  height: 200px;
  z-index: -1;
  border-radius: 50%;
}

.Login_new:hover {
  border-color: #ef4f5f;
  background: #fff;
  color: #ef4f5f;
}

/* white-Navbar end */

.form-container {
  border: 1px solid #ddd; /* Border color */
  padding: 20px; /* Padding around the form */
  border-radius: 5px; /* Rounded corners */
  background-color: #f9f9f9; /* Light background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin: 20px auto; /* Center the form and add margin */
}
.border-box {
  border: 1px solid #ddd; /* Border color */
  padding: 20px; /* Space inside the border */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  background-color: #fff; /* Background color */
}
.product-detail-background {
  background-image: url("../public/assests/image/Rectangle 11.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 490px;
}

/* blood group dropdown */
select.form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23333" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem;
  padding-right: 2.5rem;
}

.blood_group_select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23333" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem;
  padding-right: 2.5rem;
}
/* blood group dropdown */

/* pagination start */
.pagination-outer {
  text-align: center;
}

.pagination {
  font-family: "Poppins", sans-serif;
  display: inline-flex;
  position: relative;
}

.pagination li a.page-link {
  color: #f9412b;
  background: transparent;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  line-height: 36px;
  height: 35px;
  width: 35px;
  padding: 0;
  margin: 0 7px;
  border: none;
  border-radius: 5px;
  display: block;
  position: relative;
  z-index: 0;
  transition: all 0.1s ease 0s;
}

.pagination li:first-child a.page-link,
.pagination li:last-child a.page-link {
  font-size: 30px;
  line-height: 33px;
}

.justify-content-c-d {
  justify-content: center;
}

.pagination li a.page-link:hover,
.pagination li a.page-link:focus,
.pagination li.active a.page-link:hover,
.pagination li.active a.page-link {
  color: #fff;
  background: #f9412b;
}

.pagination li a.page-link:before,
.pagination li a.page-link:after {
  content: "";
  border-radius: 5px;
  border: 2px solid #555;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
}

.pagination li a.page-link:before {
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.pagination li a.page-link:after {
  border-left-color: transparent;
  border-right-color: transparent;
}

.pagination li a.page-link:hover:before,
.pagination li a.page-link:focus:before,
.pagination li.active a.page-link:hover:before,
.pagination li.active a.page-link:before {
  left: -4px;
  right: -4px;
}

.pagination li a.page-link:hover:after,
.pagination li a.page-link:focus:after,
.pagination li.active a.page-link:hover:after,
.pagination li.active a.page-link:after {
  top: -4px;
  bottom: -4px;
}

@media only screen and (max-width: 480px) {
  .pagination {
    font-size: 0;
    display: inline-block;
  }

  .pagination li {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 15px;
  }
}

/* Pagination end */
/* Quick_contact start */

.header-container {
  position: relative;
  width: 100%; /* Set the header container to relative to allow absolute positioning inside */
}

.logo-img {
  position: absolute;
  top: 0; /* Adjust as necessary */
  left: 0; /* Align logo to the left */
  width: 150px;
  height: 60px;
  z-index: 2;
  margin-top: 50px;
  margin-left: 30px;
}

.quick-img {
  position: relative;
  top: -10px; /* Adjust this value to shift the image vertically */
  right: 0; /* Align it towards the right end */
  width: 150px;
  height: auto;
  width: 100%;
  height: 200px;
}
/* quick contact */
.btn_emergency {
  background: transparent;
  font-family: inherit;
  display: inline-block;
  width: 20em;
  height: 2.6em;
  /* line-height: 2.5em; */
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  margin: 10px;
  font-size: 17px;
  z-index: 1;
  color: #ef4f5f;
  background-color: #ffff;
  border: 2px solid #ef4f5f;
  border-radius: 6px;
  position: relative;
  font-weight: 500;
  transition: all 0.3s ease;
}
.btn_emergency:hover {
  background: linear-gradient(to bottom, #ffff, #ffff);
  animation: button-particles 1s ease-in-out infinite;
  transform: translateY(-2px);
}
.lock_icon {
  height: 45px;
  width: 45px;
  align-items: center;
  display: flex;
  justify-content: center;
}
/* Custom Radio Button */
.custom-radio {
  appearance: none;
  border: 2px solid #ccc;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: relative;
}

.custom-radio:checked {
  background-color: #ef4f5f; /* Red color when checked */
  border: 2px solid #ef4f5f;
}

.custom-radio:checked::before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Quick_contact end */

/* starting loader when qr scan */
.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5; /* Light background for a clean look */
}

.welcome-container h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ef4f5f;
  margin-bottom: 20px;
  animation: fadeIn 2s ease-in-out;
}

.welcome-animation {
  width: 300px;
  height: 300px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for better appearance */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* starting loader when qr scan */

.secondary_home {
  position: relative;
}
/* Dot start for home 2 */
.vertical-dot-img_home2 {
  top: 0;
  left: -45px;
  width: 100px;
  position: absolute;
  /* z-index: -1000; */
}

.horizontal-dot-img_home2 {
  bottom: 131px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px; /* Adjust the size as needed */
  /* z-index: -10000000; */
  position: absolute;
  height: 100px;
}

.rectangle-top_home2 {
  top: 0;
  right: 0;
  width: 100px; /* Adjust the size as needed */
}

.rectangle-bottom_home2 {
  bottom: 0;
  right: 0;
  width: 100px; /* Adjust the size as needed */
}
/* Dot end for home 2 */

.vertical-dot-on-card-1 {
  position: absolute;
  top: -30px; /* Adjust as needed */
  left: -40px; /* Adjust as needed */
  width: 150px; /* Adjust size */
}

.horizontal-dot-on-card-1 {
  position: absolute;
  top: 85%; /* Adjust as needed */
  right: -13%; /* Center horizontally */
  transform: translateX(-50%);
  width: 170px; /* Adjust size */
}

.section-gap {
  padding: 50px 0px;
}

.pt50 {
  padding-top: 70px;
}

.pad-80 {
  padding: 80px 0px;
}

.posi-rl {
  position: relative;
}

.ab-quote {
  position: absolute;
  bottom: 0px;
  right: 25px;
}

@media screen and (max-width: 768px) {
  .skewed-box {
    height: 390px;
    /* left: 20px; */
  }
  .image-side {
    position: relative;
    /* width: 100%; */
    height: 348px;
  }

  .content {
    top: 0px;
    width: 100%;
  }

  .skewed-box::before {
    display: none;
  }

  .skewed-box::after {
    display: none;
  }

  .skewed-box {
    background-color: #ef4f5f;
  }
}

@media (max-width: 767px) {
  .skewed-box::after {
    right: -2px;
  }

  .skewed-box::before {
    width: 65%;
  }

  .skewed-box::after {
    right: 120px;
    width: 145px;
  }

  .image-side {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .image-side {
    width: 50%;
  }

  .skewed-box::after {
    right: 17px;
    width: 253px;
  }

  .right-main-img {
    width: 100%;
    object-fit: contain;
  }

  .rectangle-top {
    position: absolute;
    top: -28px;
    right: 66px;
    /* width: 20%; */
  }

  .rectangle-bottom {
    position: absolute;
    bottom: -32px;
    right: 70px;
    width: 20%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .image-side {
    width: 65%;
  }

  .skewed-box::after {
    right: 40px;
    width: 220px;
  }
}

@media (min-width: 1200px) and (max-width: 1439.98px) {
  .image-side {
    width: 65%;
  }

  .skewed-box::after {
    right: 40px;
    width: 270px;
  }
}

@media (min-width: 1440px) and (max-width: 2000px) {
  .image-side {
    width: 65%;
  }

  .skewed-box::after {
    right: 60px;
    width: 270px;
  }
}

@media (min-width: 2001px) {
  .image-side {
    width: 65%;
  }

  .skewed-box::after {
    right: 160px;
    width: 270px;
  }
}

/* ThreeItemScroll.css */
.slider-container {
  width: 900px; /* Set the container width */
  overflow: hidden;
  margin: auto;
}

.slider {
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-behavior: smooth;

  /* Hide scrollbar (for WebKit browsers like Chrome, Safari) */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
  scrollbar-width: none; /* Firefox */
}

.slider::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.slider-item {
  flex: 0 0 auto;
  width: 300px;
  margin: 0 10px;
  scroll-snap-align: start;
}

.slider-item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.ov-x-h {
  overflow-x: hidden;
}

.slick-track {
  display: flex;
  align-items: center !important;
}

@media (max-width: 576px) {
  .content {
    position: relative;
    z-index: 150;
    color: white;
    padding: 32px;
    left: 0px;
    width: 100%;
    top: 0px;
  }

  .section-gap {
    padding: 40px 0px;
  }

  .right-main-img {
    max-width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
  }

  .left-content_QR_sticker {
    padding-left: 15px;
  }

  .sm-between {
    display: flex !important;
    justify-content: space-between !important;
  }

  .skewed-box {
    height: auto;
  }

  .sm-flex-col {
    flex-direction: column-reverse !important;
  }

  .sm-col-rev {
    flex-direction: column-reverse;
  }
}

@media (max-width: 425px) {
  .mobile-logo {
    width: 143px !important;
    padding-left: 0px !important;
    height: auto !important;
    margin-top: 20px !important;
    margin-left: 15px !important;
  }

  .fs-20 {
    font-size: 16px !important;
  }

  .fs-13 {
    font-size: 13px !important;
  }

  .fs-16 {
    font-size: 16px !important;
  }

  .sm-pic {
    width: 15px !important;
    height: 20px !important;
    margin-right: 5px !important;
  }

  .lock_icon {
    height: 35px;
    width: 35px;
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 1px;
  }

  .message-text {
    font-weight: 500;
    color: #292929 !important;
    transition: color 0.3s ease;
    font-size: 15px;
  }

  .quick-img {
    position: relative;
    top: -10px;
    right: 0;
    width: 150px;
    height: auto;
    width: 100%;
    height: 140px;
  }

  .btn_emergency {
    font-size: 15px;
  }
}

.bg-dark {
  background-color: #212529 !important;
  background: #292929 !important;
}

/*  */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  animation: fadeInOverlay 0.3s; /* Animation for overlay */
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 450px;
  animation: fadeIn 0.3s;
  position: relative;
}

/* .message-box {
  background: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding: 15px;
  margin: 20px 0;
} */

.message-box {
  /* background: #f8f9fa; */
  /* border: 1px solid #ced4da; */
  /* border-radius: 8px; */
  padding: 15px;
  margin: 20px 0 0;
  color: black;
  font-size: 20px;
  text-align: start;
}

.popup-message {
  margin: 0;
}

.alert-icon {
  font-size: 40px;
  color: #f39c12;
  margin-bottom: 10px;
}

.close-button {
  position: absolute;
  top: -13px;
  right: 9px;
  border: none;
  background: transparent;
  font-size: 40px;
  cursor: pointer;
  font-weight: 300;
  color: #a9a9a9;
}

.btn-success {
  margin-top: 10px;
}

.dial-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader.dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader.dots span {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  border-radius: 50%;
  background: #ef4f5f;
  animation: bounce 0.6s infinite alternate;
}

.loader.dots span:nth-child(1) {
  animation-delay: 0s;
}

.loader.dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loader.dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  to {
    transform: translateY(-20px);
    opacity: 0.7;
  }
}

.popup-overlay_termspopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s;
}

.popup-content_termspopup {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  max-width: 800px; /* Adjust width as needed */
  height: 80%;
  overflow-y: auto;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  animation: scaleIn 0.3s;
  position: relative; /* To position the close button */
}

.close-button_termspopup {
  position: absolute;
  top: 10px;
  right: 12px;
  background: none;
  border: none;
  font-size: 26px;
  cursor: pointer;
  color: #2f2f2f; /* Change color as needed */
}

.terms-container {
  max-height: 60vh;
}

.term-description_termspopup {
  max-height: 100%;
  overflow-y: auto;
}

/* New button container for right alignment */
.button-container {
  display: flex;
  margin-top: 20px; /* Add some space above the button */
}

/* Custom Scrollbar Styles */
.term-description_termspopup::-webkit-scrollbar {
  width: 8px;
}

.term-description_termspopup::-webkit-scrollbar-thumb {
  background-color: red; /* Thumb color */
  border-radius: 10px;
}

.term-description_termspopup::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Track color */
}

/* Custom Scrollbar Styles for Firefox */
.term-description_termspopup {
  scrollbar-width: thin;
  scrollbar-color: red rgba(0, 0, 0, 0.1);
}

.error-message {
  color: red;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.7);
  }
  to {
    transform: scale(1);
  }
}

/* 09-11-2024 */

.align-items-baseline {
  align-items: baseline;
}

.mt60 {
  margin-top: 60px;
}

/* 11-11-2024 */

.btn-mb50 {
  margin-bottom: 140px;
}

.product-img {
  display: flex;
  align-items: center;
}

.order-gap div {
  margin: 10px 0px;
}

.db-shadow {
  padding: 20px;
  margin: 35px 0px;
  height: 260px;
  filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
  position: relative;
}

.pf-a {
  position: absolute;
  top: -33px;
}

.pt-20 {
  padding-top: 20px;
}

.ps-rel {
  position: relative;
}

.ab-dot-n {
  position: absolute;
  top: 21%;
  left: 60%;
  transform: translate(-50%, -50%);
}

.txt-main {
  color: #ef4f5f;
}

.new-btn-d {
  width: 100%;
  background: #ef4f5f;
  border-radius: 6px;
  border: none;
  color: white;
  padding: 10px;
  margin-top: 10px;
}

.pv-txt {
  text-align: start;
  margin-top: 17px;
  font-weight: 500;
  margin-bottom: 4px;
}

.text-black {
  color: #000000b3 !important;
  text-decoration: none;
}

.navbar .nav-link a {
  color: #333333 !important;
  text-decoration: none;
}

.navbar .nav-link a:hover {
  opacity: 1;
  color: #ef4f5f !important;
}

/* 23-11-2024  */

.term-head {
  position: relative;
  font-size: 2em;
  font-weight: bold;
  background: linear-gradient(84.22deg, #ff416c 5.89%, #ff4b2b 93.59%);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 40px;
  text-align: center;
}

.term-head::before,
.term-head::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 40%;
  height: 2px;
  background: #ff414f;
  transform: translateY(-50%);
}

.term-head::before {
  left: 0;
}

.term-head::after {
  right: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  /* For tablets and smaller screens */
  .term-head {
    font-size: 1.5em; /* Adjust font size for smaller screens */
  }

  .term-head::before,
  .term-head::after {
    width: 30%; /* Adjust width of the lines */
  }
}

@media (max-width: 480px) {
  /* For mobile screens */
  .term-head {
    font-size: 1.2em; /* Further reduce font size */
  }

  .term-head::before,
  .term-head::after {
    width: 20%; /* Adjust width of the lines for mobile */
  }
}

.term-head {
  position: relative;
  font-size: 2em;
  font-weight: bold;
  background: linear-gradient(84.22deg, #ff416c 5.89%, #ff4b2b 93.59%);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 40px;
  text-align: center;
}

.term-head::before,
.term-head::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 35%;
  height: 2px;
  background: #ff414f;
  transform: translateY(-50%);
}

.term-head::before {
  left: 0;
}

.term-head::after {
  right: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  /* For tablets and smaller screens */
  .term-head {
    font-size: 1.5em; /* Adjust font size for smaller screens */
  }

  .term-head::before,
  .term-head::after {
    width: 30%; /* Adjust width of the lines */
  }
}

@media (max-width: 480px) {
  /* For mobile screens */
  .term-head {
    font-size: 1.2em; /* Further reduce font size */
  }

  .term-head::before,
  .term-head::after {
    width: 20%; /* Adjust width of the lines for mobile */
  }
}

/* ======================= */

.ref-policy {
  position: relative;
  font-size: 2em;
  font-weight: bold;
  background: linear-gradient(84.22deg, #ff416c 5.89%, #ff4b2b 93.59%);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 40px;
  text-align: center;
}

.ref-policy::before,
.ref-policy::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 32%;
  height: 2px;
  background: #ff414f;
  transform: translateY(-50%);
}

.ref-policy::before {
  left: 0;
}

.ref-policy::after {
  right: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  /* For tablets and smaller screens */
  .ref-policy {
    font-size: 1.5em; /* Adjust font size for smaller screens */
  }

  .ref-policy::before,
  .ref-policy::after {
    width: 26%; /* Adjust width of the lines */
  }
}

@media (max-width: 480px) {
  /* For mobile screens */
  .ref-policy {
    font-size: 1.2em; /* Further reduce font size */
  }

  .ref-policy::before,
  .ref-policy::after {
    width: 15%; /* Adjust width of the lines for mobile */
  }
}

/* ================================== */

.pri-policy {
  position: relative;
  font-size: 2em;
  font-weight: bold;
  background: linear-gradient(84.22deg, #ff416c 5.89%, #ff4b2b 93.59%);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 40px;
  text-align: center;
}

.pri-policy::before,
.pri-policy::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 38%;
  height: 2px;
  background: #ff414f;
  transform: translateY(-50%);
}

.pri-policy::before {
  left: 0;
}

.pri-policy::after {
  right: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  /* For tablets and smaller screens */
  .pri-policy {
    font-size: 1.5em; /* Adjust font size for smaller screens */
  }

  .pri-policy::before,
  .pri-policy::after {
    width: 35%; /* Adjust width of the lines */
  }

  .sm-pt-15 {
    padding-top: 15px;
  }
}

@media (max-width: 480px) {
  /* For mobile screens */
  .pri-policy {
    font-size: 1.2em; /* Further reduce font size */
  }

  .pri-policy::before,
  .pri-policy::after {
    width: 28%; /* Adjust width of the lines for mobile */
  }

  .sm-pt-15 {
    padding-top: 15px;
  }
}

/* ================== */

/* Responsive Css  */

/* Global Media Queries for responsive design */

/* For large devices (992px and above) */
@media (min-width: 992px) {
  /* .right-main-img {
    max-width: 100%;
  } */

  .rectangle-top,
  .rectangle-bottom {
    right: 32px;
    width: 20%;
  }

  .container {
    gap: 30px;
  }
}

/* For tablets and medium devices (768px and below) */
@media (max-width: 992px) {
  .ab-dot-n {
    display: none;
  }

  .left-arrow {
    display: none;
  }
  .isolation-image {
    display: none; /* Hide isolation image on medium devices */
  }
  .horizontal-dot,
  .vertical-dot {
    display: none; /* Hide dots on screens below 992px */
  }
  .vertical-dot-img_home2,
  .horizontal-dot-img_home2,
  .vertical-dot-on-card-1,
  .horizontal-dot-on-card-1 {
    display: none;
  }

  .right-arrow-between-cards-1-2,
  .left-arrow-below-cards-2-3,
  .right-arrow-between-cards-3-4 {
    display: none; /* Hide arrows on smaller screens */
  }

  .md-flex-dcol {
    flex-direction: column-reverse;
  }

  /* .sm-between{
  justify-content: space-between !important;
 } */
}
@media (max-width: 768px) {
  .ab-dot-n {
    display: none;
  }

  .custom-navbar .custom-navbar-nav li {
    font-size: 15px;
    justify-content: center;
  }
  .home_1 {
    height: 400px;
    object-fit: cover;
  }

  .Rectangle_1 {
    height: 400px;
  }

  .Header_homepage h1 {
    font-size: 28px;
  }

  .Header_homepage h1 span {
    font-size: 30px;
  }

  .navbar-brand img {
    height: 45px;
  }
  .left-content_QR_sticker h2 {
    font-size: 24px;
  }
  /* .left-content_QR_sticker p {
      margin-bottom: 20px;
      font-size: 14px; 
    }
      
     */

  .right-main-img {
    max-width: 90%;
    /* height: 500px; */
    position: relative;
    z-index: 1;
  }
  /* .rectangle-top, .rectangle-bottom {
      right: 50px;
      width: 18%;
      display: none;
    } */
  .sm-col-rev {
    flex-direction: column-reverse;
  }
  .text-container {
    font-size: 26px;
  }
  .right-main-img {
    max-width: 100%;
  }
  /* .rectangle-top, .rectangle-bottom {
        right: 10px;
        width: 25%;
      } */
  .left-content {
    padding: 2rem;
  }
  .left-arrow {
    display: none;
  }
  .horizontal-dot,
  .vertical-dot {
    display: none; /* Hide dots on screens below 992px */
  }

  .md-flex-dcol {
    flex-direction: column-reverse;
  }
}

/* For small devices (landscape phones, 576px and below) */
@media (max-width: 576px) {
  .custom-navbar .navbar-brand img {
    height: 40px;
    margin-left: 1rem;
  }
  .custom-navbar .custom-navbar-nav li {
    font-size: 10px;
    justify-content: center;
  }

  .home_1,
  .Rectangle_1 {
    height: 400px;
    width: 100%;
  }

  .Header_homepage h1 {
    font-size: 24px;
  }

  .Header_homepage h1 span {
    font-size: 26px;
  }

  .btn_main {
    width: 110px;
    height: 40px;
    font-size: 14px;
    padding: 2px;
    border-radius: 16px;
  }

  .left-content,
  .right-main-img {
    padding: 1rem;
  }

  .rectangle-top,
  .rectangle-bottom {
    width: 15%;
  }

  .text-container {
    font-size: 24px;
  }

  .sm-col-rev {
    flex-direction: column-reverse;
  }
  .left-arrow {
    display: none;
  }
  .isolation-image {
    display: none;
  }
  .horizontal-dot,
  .vertical-dot {
    display: none; /* Hide dots on screens below 992px */
  }
  .vertical-dot-img_home2,
  .horizontal-dot-img_home2,
  .vertical-dot-on-card-1,
  .horizontal-dot-on-card-1 {
    display: none;
  }
}

@media (max-width: 576px) {
  .sm-none {
    display: none;
  }

  .ab-dot-n {
    display: none;
  }

  .bg-main {
    background: #ef4f5f;
  }

  .main_text {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    z-index: 2;
    color: white;
  }

  .banner_home {
    position: relative;
    height: 350px;
  }
  .left-arrow {
    display: none;
  }
  .horizontal-dot,
  .vertical-dot {
    display: none;
  }
  .vertical-dot-img_home2,
  .horizontal-dot-img_home2,
  .vertical-dot-on-card-1,
  .horizontal-dot-on-card-1 {
    display: none;
  }

  .content {
    position: relative;
    z-index: 150;
    color: white;
    padding: 32px;
    left: 0px;
    width: 100%;
    top: 0px;
  }
}

@media (max-width: 450px) {
  .mt60 {
    margin-top: 0px !important;
  }

  .sm-pt0 {
    padding-top: 0px !important;
  }

  .sm-mt-0 {
    margin-top: 0px !important;
  }

  p {
    text-align: justify;
  }

  .sm-flex-dcol {
    flex-direction: column-reverse;
  }

  .rectangle-top,
  .rectangle-bottom {
    display: none;
  }

  .right-main-img {
    margin-top: 15px;
  }

  .sm-order2 {
    order: 2;
  }

  .pad-80 {
    padding: 25px 0px !important;
  }

  .sm-w40 img {
    width: 45px !important;
  }

  .sm-mt15 {
    margin-top: 15px;
  }

  .sm-ms-0 {
    margin-left: 0px !important;
  }

  .sm-m0 {
    margin: 0px !important;
  }

  .Register-logo-img {
    height: 51px !important;
    padding-left: 0px !important;
    width: 140px !important;
    margin-top: 20px !important;
    margin-left: 20px !important;
  }

  .course_section {
    margin: 0 !important;
  }

  .product-detail-background {
    height: 270px !important;
  }

  .card {
    height: auto !important;
  }

  .ab-dot-n {
    display: none;
  }

  .pf-a {
    position: absolute;
    top: -23px !important;
  }

  .popup-message {
    font-size: 18px;
  }
}

/* New Loader  */
